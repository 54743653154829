import { useRef } from 'react'
import { useAutosizeTextArea } from 'src/hooks/useAutosizeTextArea'
import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export function Textarea({
  placeholder = '',
  value = '',
  onChange,
  onKeyPress,
  label,
  name,
  error,
  description,
  isDisabled = false,
  autoFocus = false,
  rows = 1,
  isRequired = false,
}: any) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosizeTextArea(textAreaRef.current, value)

  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {isRequired && '*'}
          {error && <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />}
        </label>
      )}
      {description && <div className={styles.description}>{description}</div>}
      <textarea
        ref={textAreaRef}
        id={name}
        // eslint-disable-next-line
        autoFocus={autoFocus}
        autoComplete="off"
        className={`${styles.textarea} ${error ? styles.error : ''}`}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onKeyPress={onKeyPress}
        rows={rows}
        disabled={isDisabled}
        name={name}
      />
    </>
  )
}
