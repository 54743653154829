import { isSafeNumber, parse } from 'lossless-json'
import { stringifyError } from './error'
import { HTTPError } from './http-error'

export enum NETWORK_CALL_STATUS {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export function customNumberParser(value) {
  return !isSafeNumber(value) ? BigInt(value) : parseFloat(value)
}

async function getJSON(input: RequestInfo, init?: RequestInit) {
  try {
    console.info(
      `%c Making: ${init?.method} request with url: ${input}`,
      'background: blue; color: white; display: block;',
    ) // eslint-disable-line
    // const response = await fetch(input, init)
    // const responseJSON = await response.json()

    // return { response, json: responseJSON }
    const response: any = await fetch(input, init)
    const responseText: any = await response.text()
    // eslint-disable-line
    const responseJSON = parse(responseText, null as any, customNumberParser) // await response.json();

    return { response, json: responseJSON }
  } catch (err) {
    throw new Error(
      stringifyError(`Networking/getJSON: An error was encountered while fetching ${JSON.stringify(input)}`, err),
    )
  }
}

export async function apiCall<T>(path: string, init?: RequestInit): Promise<T> {
  let response
  let json: T | any
  try {
    const token = (localStorage && localStorage.getItem('token')) || ''
    const jsonRespInfo = await getJSON(`${path}`, {
      ...init,
      headers: {
        ...(!path.includes('mailer')
          ? {
              'Account-Token': token || '',
            }
          : {}),

        'Content-Type': 'application/json',
        ...init?.headers,
      },
    })
    response = jsonRespInfo.response
    json = jsonRespInfo.json

    if (json[0] && json[0].toString().startsWith('e_')) {
      throw json
    }
  } catch (err) {
    if (err instanceof HTTPError) throw err

    throw new Error(
      stringifyError(`Networking/apiCall: An error was encountered while making api call to ${path}`, err),
    )
  }

  if (!response.ok)
    console.info(
      `%c Error making: ${init?.method} request with url: ${path}`,
      'background: red; color: white; display: block;',
    )

  if (!response.ok) throw new HTTPError(response, 'Problem while making API call')

  return json
}
