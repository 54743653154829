import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getCurrentUser: async () =>
    apiCall(`${config.baseAPIUrl}/account/current`, {
      method: 'POST',
    }),

  logout: async () =>
    apiCall(`${config.baseAPIUrl}/account/logout`, {
      method: 'POST',
    }),

  updateUserProfile: async (params) =>
    apiCall(`${config.baseAPIUrl}/account/update?`, {
      method: 'POST',
      body: JSON.stringify(params)
    }),
}
