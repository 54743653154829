import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getTaskHistory: async ({ page }) =>
    apiCall(`${config.baseAPIUrl}/account/job-list?page=${page}&limit=20`, {
      method: 'POST',
      // headers: {
      //   'API-TOKEN': token,
      // },
    }),
}
