import { useInfiniteQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getTaskHistoryList } from 'src/queries/reactQueryKeys'
import { api } from './api'

export const useGetTaskHistory = (enabled = true, jobId = null) =>
  useInfiniteQuery(
    getTaskHistoryList('1'),
    async ({ pageParam = 1 }) => {
      try {
        const response = await api.getTaskHistory({ page: pageParam })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_account_token_empty')) {
            return []
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @s-ignore
        // throw new Error(error?.message)
        return error
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: jobId ? 7000 : 60000,
      cacheTime: 0,
      enabled: enabled || jobId === null,
      keepPreviousData: false,
    },
  )

export const invalidateTaskHistory = () => queryClient.invalidateQueries({ queryKey: getTaskHistoryList('1') })
