import { createBrowserRouter } from 'react-router-dom'
import { MetaTitle } from './components/MetaTitle'
import { Prices } from './components/Prices/Prices'
import { AppLayout } from './layouts/AppLayout'
import { DashboardLayout } from './layouts/DashboardLayout'
import { Footer } from './layouts/Footer'
import { BlockedPage } from './pages/BlockedPage/NotFoundPage'
import { BlogPage } from './pages/BlogPage/BlogPage'
import { BlogPageOne } from './pages/BlogPage/BlogPageOne'
import { BlogPageThree } from './pages/BlogPage/BlogPageThree'
import { BlogPageTwo } from './pages/BlogPage/BlogPageTwo'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'
import { DocsPage } from './pages/DocsPage/DocsPage'
import { LandingPage } from './pages/Landing'
import { MagicLinkPage } from './pages/MagicLinkPage/MagicLinkPage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'
import { StatsPage } from './pages/StatsPage/StatsPage'
import { TermsPage } from './pages/Terms/TermsPage'
import { Text2Ai } from './pages/Text2Ai/Text2AI'

export enum ROUTES {
  landing = '/',
  dashboard = 'dashboard',
  creatorArea = 'creator',
  quick = 'quick',
  marketplace = 'marketplace',
  personal = 'personal',
  trending = 'trending',
  auth = 'auth',
  spaces = 'spaces',
  task = 'task/:taskId',
  tasks = 'tasks',
  updates = 'project-updates',
  tasksIds = ':categoryId/:taskId',
  history = 'history/:historyId',
  terms = 'terms-of-use',
  earn = 'influencer-program',
  landingPro = 'landing',
  teams = 'teams',
  text2image = 'text-to-image',
}

export const router = createBrowserRouter([
  {
    path: ROUTES.landing,
    element: <LandingPage />,
  },
  {
    path: ROUTES.auth,
    element: <MagicLinkPage />,
  },

  {
    path: ROUTES.text2image,
    element: (
      <DashboardLayout>
        <MetaTitle title="Text to Image" />
        <Text2Ai />
      </DashboardLayout>
    ),
  },

  {
    path: ROUTES.dashboard,
    element: (
      <DashboardLayout>
        <MetaTitle title="Dashboard" />
        <DashboardPage />
      </DashboardLayout>
    ),
  },

  {
    path: 'stats',
    element: (
      <DashboardLayout>
        <MetaTitle title="API stats" />
        <StatsPage />
      </DashboardLayout>
    ),
  },

  {
    path: 'docs',
    element: (
      <DashboardLayout>
        <MetaTitle title="API Documentation" />
        <DocsPage />
      </DashboardLayout>
    ),
  },
  {
    path: 'prices',
    element: (
      <DashboardLayout>
        <MetaTitle title="Prices Picogen" />
        <Prices shouldShowUrl />
      </DashboardLayout>
    ),
  },

  {
    path: 'blog',
    element: (
      <AppLayout>
        <BlogPage />
        <Footer />
      </AppLayout>
    ),
  },

  {
    path: 'blog/:id',
    element: (
      <AppLayout>
        <BlogPageOne />
      </AppLayout>
    ),
  },

  {
    path: 'blog/ultimate-midjourney-handbook',
    element: (
      <AppLayout>
        <MetaTitle title="Ultimate Handbook for Crafting Exceptional Midjourney Prompts" />
        <BlogPageOne />
      </AppLayout>
    ),
  },

  {
    path: 'blog/impact-of-midjourney-ai',
    element: (
      <AppLayout>
        <MetaTitle title="Unfolding the impact of midjourney AI on the digital art landscape" />
        <BlogPageTwo />
      </AppLayout>
    ),
  },
  {
    path: 'blog/definitive-guide-to-midjourney',
    element: (
      <AppLayout>
        <MetaTitle title="The Definitive Guide to Mastering Midjourney Prompts: Tips & Tricks for Professionals" />
        <BlogPageThree />
      </AppLayout>
    ),
  },

  {
    path: ROUTES.terms,
    element: (
      <DashboardLayout>
        <MetaTitle title="Terms of use" />
        <TermsPage />
      </DashboardLayout>
    ),
  },

  {
    path: 'blocked',
    element: (
      <div>
        <MetaTitle title="Blocked" />
        <BlockedPage />
      </div>
    ),
  },
  {
    path: '/*',
    element: (
      <div>
        <MetaTitle title="404 - not found" />
        <NotFoundPage />
      </div>
    ),
  },
])
