/* eslint-disable react/jsx-one-expression-per-line */
import { Button } from 'src/core/ds/button'
import { Loader } from 'src/core/ds/loader'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'
import { useGetUsages } from './query'

export function StatsPage() {
  const { data: currentUser, isLoading } = useGetCurrentUser()
  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    data: transactionItems,
  } = useGetUsages()

  const transactions: any = transactionItems?.pages?.flatMap((page) => page?.items ?? page)

  if (isLoading) {
    return (
      <div className={styles.dashboard}>
        <Loader isBig />
      </div>
    )
  }

  if (!isLoading && !currentUser) {
    return (
      <div className={styles.dashboard}>
        <h1>You are not authorized</h1>
      </div>
    )
  }

  return (
    <div className={styles.dashboard}>
      <h1>API statistics</h1>

      <div className={styles.apiKeys}>
        <div className={styles.card}>
          <h2>Usages</h2>

          {transactions?.length === 0 && <p>No transactions</p>}

          {transactions?.length > 0 && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credits</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((key) => (
                  <tr key={key.id}>
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    <td>{formatDate(Number(key.created_at * 1000) as Date, DateFormats.datePickerFullDate)}</td>
                    <td>{key.value}</td>
                    <td>{key.operation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className={styles.more}>
            {hasNextPage && (
              <div className={styles.tableActions}>
                <Button
                  type="button"
                  label="Load more"
                  isLoading={isFetchingNextPage}
                  disabled={!hasNextPage || isFetchingNextPage}
                  onClick={fetchNextPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
