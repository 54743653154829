// import { useIntl } from 'react-intl'
import { Icon, IconNames, IconSize } from '../icon'
import { Loader } from '../loader'
// eslint-disable-next-line import/no-cycle
import styles from './index.module.scss'
import { ButtonSizes, ButtonType, ButtonVariants } from './types'

type ButtonProps = {
  type: ButtonType
  size?: ButtonSizes
  variant?: ButtonVariants
  onClick?: any
  label?: string
  icon?: IconNames
  disabled?: boolean
  title?: string
  isLoading?: boolean
  isIconOnLeft?: boolean
  iconColor?: string
  tooltip?: string
  tooltipOnLeft?: boolean
  withLoader?: boolean
}

export function Button({
  type = 'button',
  variant = ButtonVariants.primary,
  icon,
  isLoading = false,
  size = ButtonSizes.md,
  onClick,
  disabled = false,
  label,
  title,
  isIconOnLeft = false,
  iconColor = '#fff',
  withLoader = false,
  tooltip,
  tooltipOnLeft = false,
}: ButtonProps) {
  // const intl = useIntl()
  // let buttonLabel

  // if (label) {
  //   buttonLabel = intl.formatMessage({ id: label })
  // }

  return (
    <button
      // eslint-disable-next-line
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        ${styles.button}
        ${styles[variant]}
        ${styles[size]}
        ${isIconOnLeft ? styles.leftIcon : ''}
			`}
    >
      {tooltip && (
        <div className={`${styles.tooltip} ${tooltipOnLeft ? styles.leftTooltip : undefined}`}>{tooltip}</div>
      )}
      <span>{label}</span>
      {isLoading || withLoader ? <Loader /> : <Icon size={IconSize.xs} name={icon} color={iconColor} /> || null}
    </button>
  )
}
