import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UItoreType {
  isNoisyPricePopupOpen: boolean
  isCreditsInfoPopupOpen: boolean
  isPricesPopupOpen: boolean
  isRefferalModalOpen: boolean
  isCreateNewSpaceOpen: boolean
  onBoardingModalDalle: boolean
  isAddToSpaceModalOpen: boolean
  isAddToTeamModalOpen: boolean
  isDeleteSpaceModalOpen: boolean
  isAuthModalOpen: boolean
  personalSpaceSorting: 'popular' | 'recent' | 'alphabet'
  publicSpaceSorting: 'popular' | 'recent' | 'alphabet'
  personalAreaSorting: 'popular' | 'recent' | 'alphabet'
  marketplaceSorting: 'popular' | 'recent' | 'alphabet'
  creatorAreaSorting: 'popular' | 'recent' | 'alphabet'
  trendingAreaSorting: 'popular' | 'recent' | 'alphabet'
  addToSpaceTaskBuffer: any
  isEditSpaceModalOpen: boolean
  isSettingsModalOpen: boolean
  isAddTeamMemberModalOpen: boolean
  isPROAccount: boolean
  editSpaceBuffer: any
  deleteSpaceBuffer: any
  selectedAsideSpacesTab: number
  selectedAsideSectionTab: number
  selectedAIModel: number
  selectedAsideSpaceId: null | any
  selectedAIModelId: null | string
  updateUIStore: (key: any, val: any) => void
  openAddToSpaceModal: (task: any) => void
}

export const useUIStore = create<UItoreType>()(
  persist(
    (set) => ({
      isCreditsInfoPopupOpen: false,
      isNoisyPricePopupOpen: false,
      isPricesPopupOpen: false,
      isCreateNewSpaceOpen: false,
      isRefferalModalOpen: false,
      isAddToTeamModalOpen: false,
      onBoardingModalDalle: false,
      isAddToSpaceModalOpen: false,
      isEditSpaceModalOpen: false,
      isDeleteSpaceModalOpen: false,

      isAddTeamMemberModalOpen: false,

      isSettingsModalOpen: false,

      isAuthModalOpen: false,
      marketplaceSorting: 'popular',
      publicSpaceSorting: 'popular',
      personalSpaceSorting: 'popular',
      creatorAreaSorting: 'popular',
      personalAreaSorting: 'popular',
      trendingAreaSorting: 'popular',
      selectedAIModel: 1,
      selectedAIModelId: null,
      selectedAsideSpaceId: null,

      selectedAsideSpacesTab: 0,
      selectedAsideSectionTab: 0,

      editSpaceBuffer: null,
      deleteSpaceBuffer: null,
      addToSpaceTaskBuffer: null,
      isPROAccount: false,

      updateUIStore: (key: any, val: any) => {
        set({ [key]: val })
      },
      openAddToSpaceModal: (task) => {
        set({ isAddToSpaceModalOpen: true, addToSpaceTaskBuffer: task })
      },
    }),
    {
      name: 'UIStore',
    },
  ),
)
