import { useOutsideClick } from 'src/hooks/useOutsideClick'
import styles from './index.module.scss'

interface IDropDownProps {
  visibleContent: React.ReactElement | string
  hiddenContent: React.ReactElement | string
  centered?: boolean
  lefted?: boolean
  righted?: boolean
  closeOnClick?: boolean
  isAbsoluteBottom?: boolean
}

export function Dropdown({
  visibleContent,
  hiddenContent,
  centered = false,
  lefted = false,
  righted = true,
  closeOnClick = false,
  isAbsoluteBottom = false,
}: IDropDownProps) {
  const { changedBlockRef, isBlockActive, setIsBlockActive } = useOutsideClick(false)

  const toggleSelect = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsBlockActive(!isBlockActive)
  }

  const closeOnClickHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (closeOnClick) {
      setIsBlockActive(false)
    }
  }

  return (
    <div ref={changedBlockRef} className={styles.container}>
      {/* eslint-disable-next-line */}
      <div className={`${styles.visible}`} onClick={toggleSelect}>
        {visibleContent}
      </div>
      {isBlockActive && (
        // eslint-disable-next-line
        <div
          className={`${styles.hidden} ${righted ? styles.righted : ''} ${centered ? styles.centered : ''} ${
            lefted ? styles.lefted : ''
          } ${isAbsoluteBottom ? styles.isAbsoluteBottom : ''}`}
          onClick={closeOnClickHandler}
        >
          {hiddenContent}
        </div>
      )}
    </div>
  )
}
