import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getTransactionsUsages: async ({ page }) =>
    apiCall(
      `${config.baseAPIUrl}/account/transaction-list?page=${page}&limit=20&operations[]=usage`,
      // `${config.baseAPIUrl}/account/transaction-list?page=${page}&limit=20`,
      {
        method: 'GET',
      },
    ),
}
