export const copyToClipboard = async (text: string, cb: any) => {
  // if (!navigator.clipboard) return
  navigator.clipboard.writeText(text).then(() => {
    // console.log('Async: Copying to clipboard was successful!')
    if (typeof cb === 'function') {
      cb()
    }
  })
}

export function convertToWebp(url) {
  // Проверяем, является ли URL строкой и содержит ли он ".png"

  if (typeof url !== 'string' || !url.includes('.png')) {
    return url // Возвращаем исходный URL, если он не соответствует критериям
  }

  // Заменяем ".png" на ".webp"

  return url.replace(/\.png$/i, '.webp')
}
