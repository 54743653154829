import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getAPIToken: async () =>
    apiCall(`${config.baseAPIUrl}/account/api-token`, {
      method: 'GET',
    }),

  jobPolling: async (id) =>
    apiCall(`${config.baseAPIUrl}/account/job-get/${id}`, {
      method: 'GET',
      // headers: {
      //   'API-TOKEN': token,
      // },
    }),

  getTransactions: async ({ page }) =>
    apiCall(
      `${config.baseAPIUrl}/account/transaction-list?page=${page}&limit=20&operations[]=deposit&operations[]=bonus&operations[]=refund`,
      // `${config.baseAPIUrl}/account/transaction-list?page=${page}&limit=20`,
      {
        method: 'GET',
      },
    ),

  getSubscriptionPlanLink: async () =>
    apiCall(`${config.baseAPIUrl}/account/subscription`, {
      method: 'POST',
    }),
}
