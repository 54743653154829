import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetCurrentUser = () =>
  useQuery(getCurrentUserKey(), async () => {
    try {
      const token = localStorage.getItem('token')

      if (!token) return null

      const response = await api.getCurrentUser()
      const [error, data] = response as any[]

      if (error) {
        console.log('error', error.toString())

        if (error?.toString().includes('e_account_blocked')) {
          // show notification
          pushNotification({
            type: 'error',
            title: 'Your account is blocked.',
          })
          // redirect back
          window.location.href = '/blocked'

          throw new Error('')
        }

        throw new Error(error)
      }

      return data || null
    } catch (e) {
      console.error(e)

      if (e?.toString().includes('e_account_blocked')) {
        // show notification
        pushNotification({
          type: 'error',
          title: 'Your account is blocked.',
        })
        // redirect back
        window.location.href = '/blocked'

        throw new Error('')
      }

      return null
    }
  })

export const useUpdateProfile = () =>
  useMutation(api.updateUserProfile, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      pushNotification({
        type: 'success',
        title: 'Profile updated',
      })
      queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
    },
    onError: (err: any) => {
      pushNotification({
        type: 'error',
        title: 'Error updating profile.',
        subtitle: 'Try again later.',
      })

      return err
    },
  })

export const useLogoutUser = () =>
  useMutation(api.logout, {
    onSuccess: (data: any) => {
      const [error] = data

      if (error) {
        throw Error(error)
      }

      window.pusherInstance?.disconnect()
      window.pusherInstance = null
      localStorage.removeItem('token')
      // redirect to main page after logout
      window.location.href = '/'
    },
    onError: (err: any) => err,
  })
