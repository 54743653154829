/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { Markdown } from 'src/components/MarkDownWrapper'
import { Badge } from 'src/core/ds/badge'
import { BadgeType } from 'src/core/ds/badge/types'
import { Tabs } from 'src/core/ds/tabs'
import styles from './index.module.scss'

export const endpoints = [
  {
    id: 'job/generate',
    method: 'POST',
    title: 'job/generate',
    description:
      'This endpoint generates an image based on the provided parameters. It accepts POST requests only and expects a JSON body in the request.',
    parameters: [
      { name: 'prompt', type: 'String', description: 'Text prompt for image generation', required: true },
      {
        name: 'ratio',
        type: 'String',
        description: 'Image ratio in format "x:y" (e.g., "1:1", "16:9").',
        required: true,
      },
      {
        name: 'seed',
        type: 'Integer',
        description: 'Seed for random number generation. If not provided, a random seed will be used.',
        required: false,
      },
    ],
    codeExamples: {
      Terminal: `curl -X POST https://api.picogen.io/v1/job/generate \
-H "Content-Type: application/json" \
-H "API-Token: <Your-API-Token>" \
-d '{
  "prompt": "A beautiful landscape with mountains and a lake",
  "ratio": "16:9"
}'`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/generate';
const apiToken = '<Your-API-Token>';

const data = {
  prompt: "A beautiful landscape with mountains and a lake",
  ratio: "16:9"
};

axios.post(apiUrl, data, {
  headers: {
    'Content-Type': 'application/json',
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/generate'
api_token = '<Your-API-Token>'

data = {
  "prompt": "A beautiful landscape with mountains and a lake",
  "ratio": "16:9"
}

headers = {
  'Content-Type': 'application/json',
  'API-Token': api_token
}

response = requests.post(api_url, json=data, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/generate';
$apiToken = '<Your-API-Token>';

$data = [
  "prompt" => "A beautiful landscape with mountains and a lake",
  "ratio" => "16:9"
];

$options = [
  'http' => [
    'header'  => "Content-type: application/json\r\nAPI-Token: $apiToken",
    'method'  => 'POST',
    'content' => json_encode($data)
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) {
  echo "Error occurred";
} else {
  $response = json_decode($result, true);
  print_r($response);
}
`,
    },
    response: `[
  null,
  {
    "id": "EqqTkZY6hx",
    "cost": 10
  }
]`,
  },
  {
    id: 'job/blend',
    method: 'POST',
    title: 'job/blend',
    description:
      'This endpoint blends two images based on a prompt. It accepts POST requests only and expects a JSON body in the request.',
    parameters: [
      { name: 'prompt', type: 'String', description: 'Text prompt for image blending', required: true },
      { name: 'urls', type: 'Array<String>', description: 'Array of two image URLs to blend', required: true },
    ],
    codeExamples: {
      Terminal: `curl -X POST https://api.picogen.io/v1/job/blend \
-H "Content-Type: application/json" \
-H "API-Token: <Your-API-Token>" \
-d '{
  "prompt": "Blend these images into a surreal landscape",
  "urls": [
    "https://example.com/image1.jpg",
    "https://example.com/image2.jpg"
  ]
}'`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/blend';
const apiToken = '<Your-API-Token>';

const data = {
prompt: "Blend these images into a surreal landscape",
  urls: [
    "https://example.com/image1.jpg",
    "https://example.com/image2.jpg"
  ]
};

axios.post(apiUrl, data, {
  headers: {
    'Content-Type': 'application/json',
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/blend'
api_token = '<Your-API-Token>'

data = {
  "prompt": "Blend these images into a surreal landscape",
  "urls": [
    "https://example.com/image1.jpg",
    "https://example.com/image2.jpg"
  ]
}

headers = {
  'Content-Type': 'application/json',
  'API-Token': api_token
}

response = requests.post(api_url, json=data, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/blend';
$apiToken = '<Your-API-Token>';

$data = [
  "prompt" => "Blend these images into a surreal landscape",
  "urls" => [
    "https://example.com/image1.jpg",
    "https://example.com/image2.jpg"
  ]
];

Here's the updated version of the code using the latest PHP style conventions:

$options = [
  'http' => [
    'header' => [
      'Content-Type: application/json',
      "API-Token: $apiToken",
    ],
    'method' => 'POST',
    'content' => json_encode($data),
  ],
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`
    },
    response: `[
  null,
  {
    "id": "EqqTkZY6hx",
    "cost": 30
  }
]`,
  },
  {
    id: 'job/upscale',
    method: 'POST',
    title: 'job/upscale',
    description:
      'This endpoint upscales an image based on the provided parameters. It accepts POST requests only and expects a JSON body in the request.',
    parameters: [
      { name: 'url', type: 'String', description: 'URL of the image to upscale', required: true },
      {
        name: 'resolution',
        type: 'String',
        description: 'Target resolution. Possible values: "1k", "2k", "4k", "8k"',
        required: false,
      },
      { name: 'dynamic', type: 'Integer', description: 'Dynamic parameter. Range: 1-50', required: false },
      { name: 'resemblance', type: 'Float', description: 'Resemblance parameter. Range: 0-3', required: false },
      { name: 'creativity', type: 'Float', description: 'Creativity parameter. Range: 0-1', required: false },
      { name: 'steps', type: 'Integer', description: 'Number of steps for upscaling. Range: 10-100', required: false },
    ],
    codeExamples: {
      Terminal: `curl -X POST https://api.picogen.io/v1/job/upscale \
-H "Content-Type: application/json" \
-H "API-Token: <Your-API-Token>" \
-d '{
  "url": "https://example.com/image.jpg",
  "resolution": "4k",
  "dynamic": 6,
  "resemblance": 0.5,
  "creativity": 0.3,
  "steps": 20
}'`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/upscale';
const apiToken = '<Your-API-Token>';

const data = {
  url: 'https://example.com/image.jpg',
  resolution: '4k',
  dynamic: 6,
  resemblance: 0.5,
  creativity: 0.3,
  steps: 20
};

axios.post(apiUrl, data, {
  headers: {
    'Content-Type': 'application/json',
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/upscale'
api_token = '<Your-API-Token>'

data = {
  "url": "https://example.com/image.jpg",
  "resolution": "4k",
  "dynamic": 6,
  "resemblance": 0.5,
  "creativity": 0.3,
  "steps": 20
}

headers = {
  'Content-Type': 'application/json',
  'API-Token': api_token
}

response = requests.post(api_url, json=data, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/upscale';
$apiToken = '<Your-API-Token>';

$data = [
  "url" => "https://example.com/image.jpg",
  "resolution" => "4k",
  "dynamic" => 6,
  "resemblance" => 0.5,
  "creativity" => 0.3,
  "steps" => 20
];

$options = [
  'http' => [
    'header' => [
      'Content-Type: application/json',
      "API-Token: $apiToken"
    ],
    'method' => 'POST',
    'content' => json_encode($data)
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`
    },
    response: `[
  null,
  {
    "id": "EqqTkZY6hx",
    "cost": 15
  }
]`,
  },
  {
    id: 'job/remove-background',
    method: 'POST',
    title: 'job/remove-background',
    description:
      'This endpoint removes the background from an image. It accepts POST requests only and expects a JSON body in the request.',
    parameters: [{ name: 'url', type: 'String', description: 'URL of the image to process', required: true }],
    codeExamples: {
      Terminal: `curl -X POST https://api.picogen.io/v1/job/remove-background \
-H "Content-Type: application/json" \
-H "API-Token: <Your-API-Token>" \
-d '{"url": "https://example.com/image.jpg"}'`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/remove-background';
const apiToken = '<Your-API-Token>';

const data = {
  url: 'https://example.com/image.jpg'
};

axios.post(apiUrl, data, {
  headers: {
    'Content-Type': 'application/json',
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/remove-background'
api_token = '<Your-API-Token>'

data = {
  "url": "https://example.com/image.jpg"
}

headers = {
  "Content-Type": "application/json",
  "API-Token": api_token
}

response = requests.post(api_url, json=data, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)
`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/remove-background';
$apiToken = '<Your-API-Token>';

$data = [
  "url" => "https://example.com/image.jpg"
];

$options = [
  'http' => [
    'header' => "Content-type: application/json\r\nAPI-Token: $apiToken",
    'method' => 'POST',
    'content' => json_encode($data)
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`,
    },
    response: `[
  null,
  {
    "id": "EqqTkZY6hx",
    "cost": 12
  }
]`,
  },
  {
    id: 'job/cost',
    method: 'POST',
    title: 'job/cost',
    description:
      'This endpoint calculates the cost for a specific job type based on the provided payload. It accepts POST requests only and expects a JSON body in the request.',
    parameters: [
      {
        name: 'endpoint',
        type: 'String',
        description: 'The job type. Possible values: "generate", "upscale", "blend", "remove-background"',
        required: true,
      },
      {
        name: 'payload',
        type: 'Object',
        description: 'The payload for the specific job type, matching the structure of the corresponding endpoint',
        required: true,
      },
    ],
    codeExamples: {
      Terminal: `curl -X POST https://api.picogen.io/job/cost \
-H "Content-Type: application/json" \
-H "API-Token: <Your-API-Token>" \
-d '{
  "endpoint": "generate",
  "payload": {
    "prompt": "A beautiful landscape",
    "ratio": "16:9",
    "resolution": "2k",
    "steps": 20
  }
}'`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/job/cost';
const apiToken = '<Your-API-Token>';

const data = {
  endpoint: "generate",
  payload: {
    prompt: "A beautiful landscape",
    ratio: "16:9",
    resolution: "2k",
    steps: 20
  }
};

axios.post(apiUrl, data, {
  headers: {
    'Content-Type': 'application/json',
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/job/cost'
api_token = '<Your-API-Token>'

data = {
  "endpoint": "generate",
  "payload": {
    "prompt": "A beautiful landscape",
    "ratio": "16:9",
    "resolution": "2k",
    "steps": 20
  }
}

headers = {
  'Content-Type': 'application/json',
  'API-Token': api_token
}

response = requests.post(api_url, json=data, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)
`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/job/cost';
$apiToken = '<Your-API-Token>';

$data = [
"endpoint" => "generate",
"payload" => [
"prompt" => "A beautiful landscape",
"ratio" => "16:9",
"resolution" => "2k",
"steps" => 20
]
];

$options = [
  'http' => [
    'header' => [
      'Content-type: application/json',
      "API-Token: $apiToken"
    ],
    'method' => 'POST',
    'content' => json_encode($data)
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`,
    },
    response: `[
  null,
  10
]`,
  },
  {
    id: 'job/get',
    method: 'GET',
    title: 'job/get',
    description:
      'This endpoint retrieves information about a specific job. It accepts GET requests and requires a job ID as part of the URL.',
    parameters: [{ name: 'id', type: 'String', description: 'ID of the job to retrieve', required: true }],
    codeExamples: {
      Terminal: `curl -X GET https://api.picogen.io/v1/job/get/EqqTkZY6hx \
-H "API-Token: <Your-API-Token>"`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/get/EqqTkZY6hx';
const apiToken = '<Your-API-Token>';

axios.get(apiUrl, {
  headers: {
    'API-Token': apiToken
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/get/EqqTkZY6hx'
api_token = '<Your-API-Token>'

headers = {
  "API-Token": api_token
}

response = requests.get(api_url, headers=headers)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)
`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/get/EqqTkZY6hx';
$apiToken = '<Your-API-Token>';

$options = [
  'http' => [
    'header' => "API-Token: $apiToken",
    'method' => 'GET'
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`,
    },
    response: `[
  null,
  {
    "id": "EqqTkZY6hx",
    "status": "completed",
    "payload": {
      "seed": 5836511735970310862,
      "steps": 30,
      "width": 1728,
      "height": 2304,
      "prompt": "A portrait photo in anime style of a beautiful woman",
      "guidance": 4.5,
      "negative": ""
    },
    "result": "http://api.picogen.zz/files/202407/09/b5b39af15a756fc46d220061512757a9.png",
    "duration_ms": 99267,
    "created_at": 1720494257
  }
]`,
  },

  {
    id: 'job/list',
    method: 'GET',
    title: 'job/list',
    description: 'This endpoint retrieves a list of recent jobs. It accepts GET requests and supports pagination.',
    parameters: [
      { name: 'page', type: 'Integer', description: 'Page number for pagination (default: 1)', required: false },
      {
        name: 'limit',
        type: 'Integer',
        description: 'Number of items per page (default: 10, max: 100)',
        required: false,
      },
    ],
    codeExamples: {
      Terminal: `curl -X GET "https://api.picogen.io/v1/job/list?page=1&limit=10" \
-H "API-Token: <Your-API-Token>"`,

      Javascript: `const axios = require('axios');

const apiUrl = 'https://api.picogen.io/v1/job/list';
const apiToken = '<Your-API-Token>';

axios.get(apiUrl, {
  headers: {
    'API-Token': apiToken
  },
  params: {
    page: 1,
    limit: 10
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`,

      Python: `import requests

api_url = 'https://api.picogen.io/v1/job/list'
api_token = '<Your-API-Token>'

headers = {
  "API-Token": api_token
}

params = {
  "page": 1,
  "limit": 10
}

response = requests.get(api_url, headers=headers, params=params)

if response.status_code == 200:
  print(response.json())
else:
  print(f"Error: {response.status_code}")
  print(response.text)
`,

      PHP: `<?php
$apiUrl = 'https://api.picogen.io/v1/job/list?page=1&limit=10';
$apiToken = '<Your-API-Token>';

$options = [
  'http' => [
    'header' => "API-Token: $apiToken",
    'method' => 'GET'
  ]
];

$context  = stream_context_create($options);
$result = file_get_contents($apiUrl, false, $context);

if ($result === FALSE) { /* Handle error */ }

var_dump($result);
`,
    },
    response: `[
  null,
  [
    {
      "id": "g43FRZEn85",
      "status": "completed",
      "payload": {
        "width": 832,
        "height": 1152,
        "prompt": "A photo of a beautiful woman with long hair walking in red dress in New York City",
        "endpoint": "generate",
        "negative": ""
      },
      "result": "http://api.picogen.zz/files/202407/06/d59c52c4ece5f131ac1cd300608953bb.png",
      "duration_ms": 108562,
      "created_at": 1720281436
    },
    // ... more job entries ...
  ]
]`,
  },
  {
    id: 'account/info',
    method: 'GET',
    title: 'account/info',
    description:
      "This endpoint retrieves information about the user's account, including balance, subscription details, and other account-related data. It accepts GET requests and does not require any parameters.",
    parameters: [],
    codeExamples: {
      Terminal: `curl -X GET "https://api.picogen.io/v1/account/info" \
  -H "API-Token: <Your-API-Token>"`,

      Javascript: `const axios = require('axios');

  const apiUrl = 'https://api.picogen.io/v1/account/info';
  const apiToken = '<Your-API-Token>';

  axios.get(apiUrl, {
    headers: {
      'API-Token': apiToken
    }
  })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error('Error:', error);
  });`,

      Python: `import requests

  api_url = 'https://api.picogen.io/v1/account/info'
  api_token = '<Your-API-Token>'

  headers = {
    "API-Token": api_token
  }

  response = requests.get(api_url, headers=headers)

  if response.status_code == 200:
    print(response.json())
  else:
    print(f"Error: {response.status_code}")
    print(response.text)
  `,

      PHP: `<?php
  $apiUrl = 'https://api.picogen.io/v1/account/info';
  $apiToken = '<Your-API-Token>';

  $options = [
    'http' => [
      'header' => "API-Token: $apiToken",
      'method' => 'GET'
    ]
  ];

  $context  = stream_context_create($options);
  $result = file_get_contents($apiUrl, false, $context);

  if ($result === FALSE) { /* Handle error */ }

  var_dump($result);
  `,
    },
    response: `[
    null,
    {
      "balance": 1607,
      "expiring_balance": 0,
      "expiring_at": 0,
      "subscription_plan": "start",
      "subscription_end": 0,
      "stealth": false
    }
  ]`,
  },
]
export function DocsPage() {
  const [currentIndex] = useState(0)

  return (
    <div className={styles.dashboard}>
      <h1>Picogen API Documentation</h1>

      <div className={styles.docsContent}>
        <h2>
          <span className={styles.sectionTitle}>Authorization</span>
        </h2>

        <h3>Overview</h3>
        <p>
          Picogen is a versatile image generation and manipulation API service. It harnesses cutting-edge AI models to
          create high-quality images from text descriptions, as well as modify existing images, remove backgrounds, and
          blend multiple images seamlessly. With capabilities comparable to the most popular and advanced image
          generation tools available, Picogen offers a comprehensive solution for all your image-related needs in one
          convenient API.
        </p>
        <h3>Base Flow</h3>
        <p>
          The base flow of the system follows a standardized approach for handling requests and responses. Each request
          generates a unified result in the form of a JSON structure. This structure consists of two key elements: the first
          element represents an error code or null, while the second element contains the actual result of the response. To
          ensure proper handling of the data, it is essential to validate the response. If the first element of the response
          contains an error code, it typically indicates that no valid result is available. Conversely, if the first element is
          null, you can safely retrieve the result from the second element of the response. This consistent format allows for
          efficient error handling and streamlined processing of the received data throughout the application.
        </p>
        <h3>Base URL</h3>
        <p>
          The base URL for all API requests is <span className={styles.codeBlock}>https://api.picogen.io</span>.
        </p>
        <div style={{ marginTop: '3rem' }} id="authentication" className={styles.contentBox}>
          <div className={styles.contentBoxText}>
            <h3>Authentication</h3>
            <p>
              Each API request requires an API token to be passed in the request header. You can obtain the token from
              your dashboard on the Picogen website. The header should be structured as follows:
            </p>
          </div>
          <div className={styles.contentBoxCode}>
            <div className={styles.code}>
              <Markdown markdown="API-Token: <Your-API-Token>" />
            </div>
          </div>
        </div>

        <div className={styles.contentBox}>
          <div className={styles.contentBoxText}>
            <h2>
              <span className={styles.sectionTitle}>Endpoints v1</span>
            </h2>
            <p>
              All endpoints are versioned. The version is indicated in the URL path, for example:
              <br />
              <span className={styles.codeBlock}>https://api.picogen.io/v1/job/generate</span>
            </p>

            <p>
              {' '}
              There is active endpoints that you can use to generate images and other tasks. and information about the
              job status and result.
            </p>
            <p>
              Active endpoints receive your payload and put job to processing queue. Once the job is processed, it
              returns a job ID and a cost. You can use this ID to check the status of the job and get the result.
            </p>

            <p>
              You can fetch the list of jobs using the <span className={styles.codeBlock}>/job/list</span> endpoint.
              This endpoint supports pagination and returns a list of recent jobs.
            </p>
          </div>
          <div className={styles.contentBoxCode}>
            <div className={styles.code} />
          </div>
        </div>

        {endpoints.map((endpoint, index) => (
          <div key={endpoint.id} className={styles.contentBox}>
            <div className={styles.contentBoxText}>
              <h3 id={endpoint.id}>
                {endpoint.title}
                <Badge type={BadgeType.WARNING} label={endpoint.method} />
              </h3>
              <p>{endpoint.description}</p>

              {endpoint?.parameters?.length > 0 && <h3>Parameters</h3>}

              {endpoint?.parameters?.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Parameter</th>
                      <th>Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {endpoint.parameters.map((param) => (
                      <tr key={param.name}>
                        <td>
                          {param.name} {param.required && '(required)'}
                        </td>
                        <td>{param.type}</td>
                        <td>{param.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {/* --- */}
            <div className={styles.contentBoxCode}>
              <h3>Request</h3>

              <div className={styles.codeExampleBox}>
                <Tabs currentIndex={currentIndex} tabs={Object.keys(endpoints[index].codeExamples)}>
                  {Object.entries(endpoints[index].codeExamples).map(([lang, code], index) => (
                    <div key={index} className={styles.code}>
                      <Markdown
                        markdown={`\`\`\`${lang === 'Terminal' ? 'bash' : lang.toLowerCase()}\n${code}\n\`\`\``}
                      />
                    </div>
                  ))}
                </Tabs>

                <h3>Response</h3>

                <p>
                  The response includes an ID, which is the job ID you can use in
                  <span className={styles.codeBlock}>/job/get</span>, and the cost, which shows how many credits were
                  spent on the request.
                </p>

                <div className={styles.code}>
                  <Markdown markdown={`\`\`\`json\n${endpoints[index].response}\n\`\`\``} />
                </div>
              </div>
            </div>
          </div>
        ))}

        <h3>Types</h3>
        <p>
          There are several types of data that can be used in the API. These include:
        </p>
        <p>
          <ul>
            <li>
              <strong>String:</strong> A sequence of characters, enclosed in quotes (e.g., &quot;Hello, world!&quot;)
            </li>
            <li>
              <strong>Integer:</strong> A whole number without decimal points (e.g., 42, -7, 0)
            </li>
            <li>
              <strong>Float:</strong> A decimal number with fractional parts (e.g., 3.14, -0.5, 2.0)
            </li>
            <li>
              <strong>Boolean:</strong> A logical value representing true or false
            </li>
          </ul>
        </p>
        <h3>Summary</h3>
        <p>
          Picogen API allows developers to generate stunning images using different models and styles. With its
          easy-to-use endpoints and flexible parameters, it provides a comprehensive tool for image generation based on
          text prompts. Remember to check the cost of each job before running them to manage your credits efficiently.
        </p>
      </div>
    </div>
  )
}
