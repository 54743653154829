import { useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { DashboardUserArea } from 'src/components/DashboardUserArea'
import { Logo } from 'src/components/Logo/Logo'
import { Loader } from 'src/core/ds/loader'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { ModalConnector } from 'src/modules/modalsConnector/ModalsConnector'
import { endpoints } from 'src/pages/DocsPage/DocsPage'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function DashboardLayout({ children }) {
  const updateUIStore = useUIStore((state) => state.updateUIStore)

  const { data: currentUser, isLoading } = useGetCurrentUser()
  const location = useLocation()
  const isDocsPage = location.pathname.startsWith('/docs')

  useEffect(() => {
    const isProAccount = currentUser?.is_subscriber // currentUser?.sub_till * 1000 > Date.now()
    updateUIStore('isPROAccount', isProAccount)
  }, [currentUser])

  if (isLoading) {
    return <Loader isBig />
  }

  return (
    <section className={styles.dashboard}>
      <aside className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.headerTop}>
            <div className={styles.headerMobile}>
              <Link to="/">
                <Logo />
              </Link>

              <div className={styles.userAreaMobile}>
                <DashboardUserArea isZen />
              </div>
            </div>

            <ul className={styles.nav}>
              {currentUser && (
                <li>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </li>
              )}
              <li>
                <NavLink to="/text-to-image">Text to Image</NavLink>
              </li>

              {currentUser && (
                <li>
                  <NavLink to="/stats">API stats</NavLink>
                </li>
              )}
              {currentUser && (
                <li>
                  <NavLink to="/prices">Prices and Plans</NavLink>
                </li>
              )}

              <li>
                <NavLink to="/docs">Docs</NavLink>
                {isDocsPage && (
                  <div className={styles.submenu}>
                    <ul>
                      <li>Authorization</li>
                      <li>
                        <Link
                          onClick={() => {
                            document.getElementById('authentication')?.scrollIntoView({ behavior: 'smooth' })
                          }}
                          to="#authentication"
                        >
                          Authentication
                        </Link>
                      </li>
                      <li>Endpoints</li>
                      {endpoints.map((endpoint) => (
                        <li key={endpoint.id}>
                          <Link
                            onClick={() => document.getElementById(endpoint.id)?.scrollIntoView({ behavior: 'smooth' })}
                            to={`#${endpoint.id}`}
                          >
                            {endpoint.id}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>

          <div className={styles.headerBottom}>
            <DashboardUserArea />
          </div>
        </div>
      </aside>
      <ModalConnector />
      <section className={styles.dashboardContainer}>{children}</section>
    </section>
  )
}
