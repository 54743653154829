import { useNavigate } from 'react-router-dom'
import { Prices } from 'src/components/Prices/Prices'
import { Modal } from 'src/core/ds/modal'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { MagicLinkForm } from '../magicLink/components/Form'

export function ModalConnector() {
  const navigate = useNavigate()
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPricesPopupOpen = useUIStore((state) => state.isPricesPopupOpen)
  const isAuthModalOpen = useUIStore((state) => state.isAuthModalOpen)

  const isLockedEntry = !!localStorage.getItem('lockedEntry')

  const onLoginSuccess = () => {
    pushNotification({
      type: 'success',
      title: 'Success Login',
      subtitle: 'You will be redirected.',
    })
    updateUIStore('isAuthModalOpen', false)
    navigate(`/dashboard`)
  }

  return (
    <>
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isAuthModalOpen', false)}
        isOpen={isAuthModalOpen}
        body={<MagicLinkForm onLoginSuccess={onLoginSuccess} />}
      />
      <Modal
        withCloseButton
        onClose={() => updateUIStore('isPricesPopupOpen', false)}
        isOpen={isPricesPopupOpen}
        body={<Prices shouldShowUrl showFree={!isLockedEntry} />}
      />
    </>
  )
}
