import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  sendPrompt: async () =>
    apiCall(`${config.baseAPIUrl}/formula/text`, {
      method: 'POST',
      body: JSON.stringify({
        prompt: 'Say hello world in Russian',
      }),
    }),

  sendDallePrompt: async (prompt: string, taskId) =>
    apiCall(`${config.baseAPIUrl}/task/run/${taskId}?${prompt}`, {
      method: 'POST',
    }),

  verifyPromptBeforeRun: async (data) =>
    apiCall(`${config.baseAPIUrl}/account/job-generate`, {
      method: 'POST',
      // headers: {
      //   'API-TOKEN': token,
      // },
      body: JSON.stringify({
        ...data,
      }),
    }),
}
