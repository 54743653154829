import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

export const api = {
  getImages: async () =>
    apiCall(`${config.baseAPIUrl}/image/list`, {
      method: 'GET',
    }),

  getArticles: async ({ page }) =>
    apiCall(`${config.baseAPIUrl}/blog/list?page=${page}&limit=50`, {
      method: 'GET',
    }),

  getArticle: async ({ id }) =>
    apiCall(`${config.baseAPIUrl}/blog/get/${id}`, {
      method: 'GET',
    }),
}
