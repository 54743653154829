const QUERY_KEYS = {
  CURRENT_USER: 'currentUser',
  ACCOUNT: 'account',
  AUTH: 'auth',
  PAYMENT_SOURCES: 'paymentSources',
  PAYMENT: 'payments',
  SELECTED_TASK: 'selectedTask',
  TASK_HISTORY: 'taskHistory',
  API_TOKEN: 'apiToken',
  JOB_DETAILS: 'jobDetails',
}

export const getCurrentUserKey = () => [QUERY_KEYS.CURRENT_USER]
export const getSelectedTaskKey = (id) => [QUERY_KEYS.SELECTED_TASK, id]
export const getTaskHistoryList = (id) => [QUERY_KEYS.TASK_HISTORY, 'list', id]
export const getApiTOkenKey = () => [QUERY_KEYS.API_TOKEN]
export const getJobDetailsKey = (id) => [QUERY_KEYS.JOB_DETAILS, id]
