import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from './components/ErrorBoundary'
import { IconsSpriteContainer } from './core/ds/iconsSprite'
import { queryClient } from './queries/reactQueryClient'
import { router } from './Routes'

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IconsSpriteContainer />
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
      <Toaster position="top-right" />
    </QueryClientProvider>
  )
}
