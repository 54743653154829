import { useInfiniteQuery, useQuery } from 'react-query'
import { getApiTOkenKey, getJobDetailsKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetApiToken = () =>
  useQuery(getApiTOkenKey(), async () => {
    const response = await api.getAPIToken()
    const [error, data] = response as any[]

    if (error) {
      if (error === 'e_task_not_published') {
        // show notification
        pushNotification({
          type: 'error',
          title: 'This task is not published yet.',
        })
        // redirect back

        throw new Error('')
      }

      if (error === 'e_task_not_found') {
        // show notification
        pushNotification({
          type: 'error',
          title: 'This task is not found.',
        })
        // redirect back

        throw new Error(error)
      }

      throw new Error(error)
    }

    return data
  })

export const useGetJobPolling = (id) =>
  useQuery(
    getJobDetailsKey(id),
    async () => {
      const response = await api.jobPolling(id)
      const [error, data] = response as any[]

      if (error) {
        if (error === 'e_task_not_published') {
          // show notification
          pushNotification({
            type: 'error',
            title: 'This task is not published yet.',
          })
          // redirect back

          throw new Error('')
        }

        if (error === 'e_task_not_found') {
          // show notification
          pushNotification({
            type: 'error',
            title: 'This task is not found.',
          })
          // redirect back

          throw new Error(error)
        }

        throw new Error(error)
      }

      return data
    },

    {
      refetchInterval: 3000,
      enabled: !!id, // Refetch the data every 3 seconds
      refetchIntervalInBackground: true, // Co
    },
  )

export const useGetTransactions = () =>
  useInfiniteQuery(
    'transactions',
    async ({ pageParam = 1 }) => {
      try {
        const response = await api.getTransactions({ page: pageParam })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_account_token_empty')) {
            return []
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @s-ignore
        // throw new Error(error?.message)
        return error
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
