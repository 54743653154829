import { create } from 'zustand'

export const useAuthStore = create<any>()((set) => ({
  email: '',
  setEmail: (email: string | number) => {
    set({ email })
  },
  isCodeStep: false,
  setIsCodeStep: (value) => {
    set({ isCodeStep: value })
  },
}))
