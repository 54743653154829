import { useState } from 'react'
import ProIcon from 'src/assets/images/icon-pro.svg'
import { Avatar } from 'src/core/ds/avatar'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useGetCurrentUser, useLogoutUser } from 'src/modules/currentUser/query'
import { useUIStore } from 'src/services/store/UIStore'
import { sendGAEvent } from 'src/utils/analytics'
import styles from './index.module.scss'

export function DashboardUserArea({ isZen = false }) {
  const { data: currentUser } = useGetCurrentUser()
  const { isLoading: isLoadingLogout, mutate } = useLogoutUser()
  // const updateUIStore = useUIStore((state) => state.updateUIStore)
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  const onLogoutHandler = async () => {
    // eslint-disable-next-line
    await mutate()
  }

  return (
    <div className={styles.navigation}>
      <div className={styles.userArea}>
        <Modal
          withCloseButton
          type="error"
          onDeny={() => setIsLogoutModalOpen(false)}
          onConfirm={onLogoutHandler}
          onClose={() => setIsLogoutModalOpen(false)}
          isOpen={isLogoutModalOpen}
          body={
            // eslint-disable-next-line
            <div className={styles.logoutModal}>
              <h1>Are you sure you want to logout?</h1>
            </div>
          }
        />

        {currentUser && (
          <Dropdown
            isAbsoluteBottom={!isZen}
            visibleContent={
              // eslint-disable-next-line
              <div className={styles.userMenus} onClick={() => sendGAEvent('account_dashboard')}>
                {!isZen && (
                  <div className={styles.icon}>
                    <Icon color="var(--color-secondary)" name={IconNames.theeDots} size={IconSize.xs} />
                  </div>
                )}
                <Avatar url={currentUser?.avatar_url} />
                {!isZen && (
                  <div className={styles.userContent}>
                    <div className={styles.userName}>{currentUser?.email}</div>
                    {isPROAccount && (
                      <div className={styles.creds}>
                        <img src={ProIcon} alt="" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            hiddenContent={
              // eslint-disable-next-line
              <div className={styles.userMenu}>
                <div className={styles.actionsFooter}>
                  <div className={styles.userMenuActions}>
                    <Button
                      variant={ButtonVariants.unstyled}
                      onClick={() => setIsLogoutModalOpen(true)}
                      type="button"
                      disabled={isLoadingLogout}
                      isLoading={isLoadingLogout}
                      label="Logout"
                    />
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}
