/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { sendGAEvent } from 'src/utils/analytics'
import { DateFormats, formatDate } from 'src/utils/dates'
import { copyToClipboard } from 'src/utils/strings'
import { api } from './api'
import styles from './index.module.scss'
import { useGetApiToken, useGetTransactions } from './query'

const IMAGE_PRICE = 0.005

function maskString(str) {
  const firstTwo = str.slice(0, 2)
  const lastThree = str.slice(-3)
  const maskedPart = str.slice(2, -3).replace(/./g, '*')

  return firstTwo + maskedPart + lastThree
}

export function DashboardPage() {
  const [isVisibleKeys, setIsVisibleKeys] = useState(false)
  const [amount, setAmount] = useState('10')
  const { data: currentUser, isLoading } = useGetCurrentUser()
  const { data: apiTokenDetails } = useGetApiToken()
  const isPROAccount = useUIStore((state) => state.isPROAccount)

  const token = (localStorage && localStorage.getItem('token')) || ''

  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    data: transactionItems,
  } = useGetTransactions()

  const refreshCreditsCount = async () => {
    await queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
    pushNotification({
      type: 'success',
      title: 'Refreshed',
    })
  }

  const onManagePlan = async () => {
    try {
      const response = await api.getSubscriptionPlanLink()
      const [error, link] = response as any
      // console.log('link', link)
      sendGAEvent('account_manage')

      if (error === 'e_customer_not_found') {
        pushNotification({
          type: 'error',
          title: 'Error',
          subtitle: 'No user found',
        })

        return
      }

      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        window?.open(link, '_blank').focus()
      })

      // console.log('response', response)
    } catch (err) {
      console.log(err)
      pushNotification({
        type: 'error',
        title: 'Error',
        subtitle: "Can't get subscription plan link",
      })
    }
  }

  const onSubmitPayment = async (event) => {
    sendGAEvent('account_deposit')

    if (+amount < 10 || Number.isNaN(+amount)) {
      event.preventDefault()
      pushNotification({
        title: 'Error',
        subtitle: 'Minimum amount is $10',
        type: 'error',
      })

      return false
    }

    return false
  }

  const onTextCopy = (copy) => {
    copyToClipboard(
      copy,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  const transactions: any = transactionItems?.pages?.flatMap((page) => page?.items ?? page)
  const userCredits = currentUser?.balance + currentUser?.expiring_balance || 0

  if (isLoading) {
    return (
      <div className={styles.dashboard}>
        <Loader isBig />
      </div>
    )
  }

  if (!isLoading && !currentUser) {
    return (
      <div className={styles.dashboard}>
        <h1>You are not authorized</h1>
      </div>
    )
  }

  return (
    <div className={styles.dashboard}>
      <h1>Dashboard</h1>

      <div className={styles.actionsList}>
        {isPROAccount && (
          <div className={styles.manage}>
            <Button
              icon={IconNames.creditCard}
              iconColor="var(--color-secondary)"
              isIconOnLeft
              variant={ButtonVariants.secondary}
              size={ButtonSizes.xs}
              onClick={onManagePlan}
              label={`Manage plan (till ${formatDate(
                // eslint-disable-next-line
                // @ts-ignore
                Number((currentUser?.sub_till || 1) * 1000) as Date,
                DateFormats.monthWithDay,
              )})`}
              type="button"
            />
          </div>
        )}
      </div>

      <div className={styles.apiKeys}>
        <div className={styles.card}>
          <h2>API keys</h2>

          {apiTokenDetails && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{isVisibleKeys ? apiTokenDetails : maskString(apiTokenDetails)}</td>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore */}
                  <td>{formatDate(Number(currentUser?.created_at * 1000) as Date, DateFormats.datePickerFullDate)}</td>
                  <td>
                    <div className={styles.keyActions}>
                      <Button
                        onClick={() => onTextCopy(apiTokenDetails)}
                        type="button"
                        tooltip="Copy API key"
                        iconColor="var(--color-secondary)"
                        variant={ButtonVariants.unstyled}
                        icon={IconNames.copy}
                      />
                      <Button
                        onClick={() => setIsVisibleKeys((prevState) => !prevState)}
                        type="button"
                        tooltip="Toggle visibility"
                        iconColor="var(--color-secondary)"
                        variant={ButtonVariants.unstyled}
                        icon={isVisibleKeys ? IconNames.eyeHide : IconNames.eyeShow}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className={styles.split}>
        <div className={styles.card}>
          <h2>
            <span>Credits</span>
            <Button
              onClick={refreshCreditsCount}
              type="button"
              tooltip="Refresh credits"
              iconColor="var(--color-secondary)"
              variant={ButtonVariants.unstyled}
              icon={IconNames.refresh}
            />
          </h2>

          <div className={styles.credits}>
            <h3>{userCredits}</h3>
            <span>~{Number(userCredits / 10)} images</span>
          </div>

          <div className={styles.buy}>
            <form action={`${config.baseAPIUrl}/account/pay?value=${amount}`} method="post">
              <input hidden name="TKN" value={token} />

              <Input
                action={<Button type="submit" onClick={onSubmitPayment} label="Buy" size={ButtonSizes.xs} />}
                value={amount}
                prefix="$"
                label="Amount"
                description="Min payment is $10. Steps in $10. The amount is rounded up (ex. $12 -> $20)."
                name="credits"
                onChange={(event) => setAmount(event.target.value)}
                placeholder="Enter amount"
              />
            </form>
          </div>

          <div className={styles.buyDetails}>
            <div>
              <span>
                {Number.isNaN(+amount / IMAGE_PRICE) ? 0 : +amount / IMAGE_PRICE}
                &nbsp;credits
              </span>
              <p>~{Number.isNaN(+amount / IMAGE_PRICE) ? 0 : Number(+amount / IMAGE_PRICE / 10)} images</p>
            </div>
          </div>

          {/* <div className={styles.notes}>
            <Icon name={IconNames.alertCircle} color="var(--color-secondary)" />
            To utilize the Midjourney API, you must either have an untouched deposit of 5000 credits or a verified
            account. Other models are available from any amount.
          </div> */}
        </div>

        {/* <div className={styles.card}>
          <h2>Features</h2>


        </div> */}

        <div className={styles.card}>
          <h2>Payments</h2>

          {transactions?.length === 0 && <p>No transactions</p>}

          {transactions?.length > 0 && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credits</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((key) => (
                  <tr key={key.id}>
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    <td>{formatDate(Number(key.created_at * 1000) as Date, DateFormats.datePickerFullDate)}</td>
                    <td>{key.value}</td>
                    <td>{key.operation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className={styles.more}>
            {hasNextPage && (
              <div className={styles.tableActions}>
                <Button
                  type="button"
                  label="Load more"
                  isLoading={isFetchingNextPage}
                  disabled={!hasNextPage || isFetchingNextPage}
                  onClick={fetchNextPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
