// eslint-disable-next-line
// @ts-nocheck
import rangeParser from 'parse-numeric-range'
import ReactMarkdown from 'react-markdown'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
// eslint-disable-next-line
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash'
// eslint-disable-next-line
import css from 'react-syntax-highlighter/dist/cjs/languages/prism/css'
// eslint-disable-next-line
import docker from 'react-syntax-highlighter/dist/cjs/languages/prism/docker'
// eslint-disable-next-line
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript'
// eslint-disable-next-line
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json'
// eslint-disable-next-line
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown'
// eslint-disable-next-line
import php from 'react-syntax-highlighter/dist/cjs/languages/prism/php'
// eslint-disable-next-line
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python'
// eslint-disable-next-line
import rust from 'react-syntax-highlighter/dist/cjs/languages/prism/rust'
// eslint-disable-next-line
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss'
// eslint-disable-next-line
import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx'
// eslint-disable-next-line
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript'
// eslint-disable-next-line
import yaml from 'react-syntax-highlighter/dist/cjs/languages/prism/yaml'
// eslint-disable-next-line
import { atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import remarkGfm from 'remark-gfm'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('tsx', tsx)
SyntaxHighlighter.registerLanguage('yaml', yaml)
SyntaxHighlighter.registerLanguage('php', php)
SyntaxHighlighter.registerLanguage('typescript', typescript)
SyntaxHighlighter.registerLanguage('javascript', javascript)
SyntaxHighlighter.registerLanguage('scss', scss)
SyntaxHighlighter.registerLanguage('css', css)
SyntaxHighlighter.registerLanguage('bash', bash)
SyntaxHighlighter.registerLanguage('rust', rust)
SyntaxHighlighter.registerLanguage('docker', docker)
SyntaxHighlighter.registerLanguage('markdown', markdown)
SyntaxHighlighter.registerLanguage('json', json)

function Pre({ children }) {
  const handleClick = (e) => {
    e.preventDefault()

    navigator.clipboard.writeText(children[0].props.children[0])
    pushNotification({
      type: 'success',
      title: 'Code copied to clipboard',
    })
  }

  return (
    <pre className={styles.wrapper}>
      <div className={styles.copy}>
        <Button onClick={handleClick} label="copy code" variant={ButtonVariants.secondary} type="button" />
      </div>
      {children}
    </pre>
  )
}

// eslint-disable-next-line
export function Markdown({ markdown }: any) {
  const syntaxTheme = atomDark // oneDark

  // eslint-disable-next-line
  const MarkdownComponents: any = {
    pre: Pre,
    // eslint-disable-next-line
    code({ node, inline, className, ...props }) {
      const hasLang = /language-(\w+)/.exec(className || '')
      const hasMeta = node?.data?.meta

      const applyHighlights: object = (applyHighlights: number) => {
        if (hasMeta) {
          const RE = /{([\d,-]+)}/
          const metadata = node.data.meta?.replace(/\s/g, '')
          const strlineNumbers = RE?.test(metadata) ? RE?.exec(metadata)[1] : '0'
          const highlightLines = rangeParser(strlineNumbers)
          const highlight = highlightLines
          const data: any = highlight.includes(applyHighlights) ? 'highlight' : null

          return { data }
        }

        return {}
      }

      return hasLang ? (
        <SyntaxHighlighter
          style={syntaxTheme}
          language={hasLang[1]}
          PreTag="div"
          className="codeStyle"
          showLineNumbers
          wrapLines={hasMeta}
          useInlineStyles
          lineProps={applyHighlights}
        >
          {props.children}
        </SyntaxHighlighter>
      ) : (
        // <SyntaxHighlighter useInlineStyles language="javascript" style={syntaxTheme}>
        //   {props.children}
        // </SyntaxHighlighter>
        <span className="pureCode">
          {/* eslint-disable-next-line */}
          <code className={className} {...props}>
            {props.children}
          </code>
        </span>
      )
    },
  }

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={MarkdownComponents}>
      {markdown}
    </ReactMarkdown>
  )
}
