/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Turnstile from 'react-turnstile'
// import { FormattedText } from 'src/components/FormattedText'
import { Logo } from 'src/components/Logo/Logo'
import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { Input } from 'src/core/ds/input'
import { useEmailService } from 'src/services/email/query'
import styles from './index.module.scss'

export function Footer() {
  const [email, setEmail] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const emailService = useEmailService()

  const { isLoading, isSuccess, mutate } = emailService.useSubscribeEmail()

  const isFormDisabled = isLoading || isSuccess

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!email.length) {
      return
    }

    await mutate({ email, token: window.cfToken })
  }

  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <div className={styles.footerTop}>
          <div className={styles.nav}>
            <ul>
              <li>
                <a href="/docs">API Docs</a>
              </li>
              <li>
                <a href="/#prices">Prices</a>
              </li>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                <a href="/#footer">Subscribe</a>
              </li>
              <li>
                Contact:
                <br />
                <a href="mailto:hello@picogen.io">hello@picogen.io</a>
              </li>
            </ul>

            <ul>
              <li>Picogen AI Image API:</li>
              <li>
                <Link to="/docs">Leading Midjourney API alternative</Link>
              </li>
              <li>Comparable to Stable Diffusion 3</li>
              <li>Outperforms DALL-E 2 & 3</li>
              <li>Affordable API for AI Image generation</li>
              <li>Remove Background, Blend images, 8K Image Upscaler</li>
            </ul>
          </div>

          <div className={styles.form}>
            <p>Don&#39;t miss updates:</p>
            <form onSubmit={onSubmit}>
              <Input
                isDisabled={isFormDisabled}
                onChange={handleEmailChange}
                value={email}
                type="email"
                placeholder="youremail@email.com"
              />
              <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="Subscribe" />
              <div className="turnstile">
                <Turnstile
                  theme="light"
                  sitekey="0x4AAAAAAAIB9jRGJdSumhWh"
                  onVerify={(token) => {
                    window.cfToken = token
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div>
            <a title="Zentask" href="/">
              <Logo />
            </a>
            <p>
              <a href="https://muvon.io">Muvon Un Limited&nbsp;</a> © 2024
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
