/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { Markdown } from 'src/components/MarkDownWrapper'
import { Container } from 'src/core/ds/container'
import { Loader } from 'src/core/ds/loader'
import ScrollToTop from 'src/hooks/ScrollToTop'
import { Footer } from 'src/layouts/Footer'
import { DateFormats, formatDate } from 'src/utils/dates'
import { useGetArticleById } from '../Landing/query'
import { slugify } from '../Landing/utils'
import styles from './index.module.scss'

export function BlogPageOne() {
  const { id } = useParams()
  const [parsedId] = id?.split('-') || []
  const { data: article, isLoading } = useGetArticleById(parsedId)

  if (isLoading) {
    return <Loader isBig />
  }

  return (
    <>
      <div
        className={styles.surface}
        style={{
          backgroundImage: `url(${article.image_url})`,
        }}
      >
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <ScrollToTop />
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={article.description} />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.description} />
        </Helmet>
        <div className={styles.hero}>
          <Container>
            <h1>{article.title}</h1>
            <p>{article.description}</p>
          </Container>
        </div>
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.date}>
            Published:
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            {formatDate(article.published_at * 1000, DateFormats.dayMonthYearDateSlash)}
          </div>
          <Markdown markdown={article.text} />
        </Container>
      </div>
      <Container>
        <section id="blog" className={styles.blog}>
          <h2>Related articles</h2>

          <div className={styles.blogList}>
            {article.similar?.map((blog) => (
              <div id={blog.id} className={styles.blogItem}>
                <Link to={`/blog/${blog.id}-${slugify(blog.title)}`}>
                  <img src={blog.thumb_url} alt="" loading="lazy" />
                </Link>
                <Link to={`/blog/${blog.id}-${slugify(blog.title)}`}>{blog.title}</Link>
                <p>{blog.description}</p>
              </div>
            ))}
          </div>
        </section>
      </Container>

      <Footer />
    </>
  )
}
