import { useInfiniteQuery } from 'react-query'
import { api } from './api'

export const useGetUsages = () =>
  useInfiniteQuery(
    'usages',
    async ({ pageParam = 1 }) => {
      try {
        const response = await api.getTransactionsUsages({ page: pageParam })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_account_token_empty')) {
            return []
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @s-ignore
        // throw new Error(error?.message)
        return error
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
