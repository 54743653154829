import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { ModalConnector } from 'src/modules/modalsConnector/ModalsConnector'
import { useUIStore } from 'src/services/store/UIStore'
import { useGetBlog } from '../Landing/query'
import { slugify } from '../Landing/utils'
import styles from './index.module.scss'

export function BlogPage() {
  const { data: blogList } = useGetBlog()
  const updateUIStore = useUIStore((state) => state.updateUIStore)
  const { data: currentUser } = useGetCurrentUser()
  const blogs = blogList?.pages?.flatMap((page) => page?.items ?? page)

  return (
    <Container>
      <section id="blog" className={styles.blog}>
        <h2>Blog</h2>

        <div className={styles.blogList}>
          {blogs?.map((blog) => (
            <div id={blog.id} className={styles.blogItem}>
              <Link to={`/blog/${blog.id}-${slugify(blog.title)}`}>
                <img src={blog.thumb_url} alt="" loading="lazy" />
              </Link>
              <Link to={`/blog/${blog.id}-${slugify(blog.title)}`}>{blog.title}</Link>
              <p>{blog.description}</p>
            </div>
          ))}
        </div>
      </section>

      {!currentUser && (
        <section className={styles.try}>
          <h3>Try Picogen API today</h3>
          <p>Get more done for less with API integration in under 5 minutes</p>

          <Button
            size={ButtonSizes.xl}
            onClick={() => {
              updateUIStore('isAuthModalOpen', true)
            }}
            type="button"
            label="Try it now"
          />
        </section>
      )}
      <ModalConnector />
    </Container>
  )
}
