import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ESCAPE } from 'src/constants/keyCodes'
// eslint-disable-next-line import/no-cycle
import { Button } from '../button'
import { ButtonVariants } from '../button/types'
import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export type Props = {
  title?: string
  subtitle?: string
  onConfirm?: any
  onDeny?: any
  isOpen: boolean
  onClose?: any
  body?: any
  type?: 'success' | 'error' | 'default' | 'help'
  withCloseButton?: boolean
}

const iconMap = {
  success: <Icon size={IconSize['2xl']} name={IconNames.checkCircle} color="var(--color-primary)" />,
  error: <Icon size={IconSize['2xl']} name={IconNames.alertCircle} color="var(--color-red)" />,
  default: <Icon size={IconSize['2xl']} name={IconNames.bell} color="var(--color-secondary)" />,
  help: <Icon size={IconSize['2xl']} name={IconNames.helpCircle} color="var(--color-secondary)" />,
}

export function Modal({
  title,
  onClose,
  isOpen = false,
  withCloseButton = false,
  type,
  body,
  onConfirm = false,
  onDeny = false,
  subtitle,
}: Props) {
  const [isOpened, setIsOpen] = useState(isOpen)

  const onPopupClose = () => {
    setIsOpen(false)
    onClose?.()
  }

  const onPopupCloseInner = (e: any) => {
    e.stopPropagation()
  }
  // eslint-disable-next-line
  const onPopupCloseOuter = (e) => {
    setIsOpen(false)
    onClose?.()
  }

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsOpen(isOpen)
    }
  }, [isOpen])

  useEffect(() => {
    window.document.addEventListener('keydown', onKeyPress)

    return () => {
      window.document.removeEventListener('keydown', onKeyPress)
    }
    // eslint-disable-next-line
  }, [title])

  const onKeyPress = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      // close
      case ESCAPE:
        onPopupClose()
        break

      default:
        break
    }
  }

  if (!isOpened) return null

  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {/* eslint-disable-next-line */}
          isOpened && ({/* eslint-disable-next-line */}
          <div onClick={(e: any) => onPopupCloseOuter(e)} className={styles.surface}>
            <motion.section
              key="modal"
              initial={{
                opacity: 0,
                // scale: 0.75,
              }}
              animate={{
                opacity: 1,
                // scale: 1,
                transition: {
                  ease: 'easeOut',
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                // scale: 0.75,
                transition: {
                  ease: 'easeIn',
                  duration: 0.15,
                },
              }}
              // transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className={styles.container}>
                {/* eslint-disable-next-line */}
                <div onClick={(e: any) => onPopupCloseInner(e)} className={styles.modal}>
                  {withCloseButton && (
                    <div className={styles.closeButton}>
                      <Button
                        onClick={onPopupClose}
                        variant={ButtonVariants.unstyled}
                        iconColor="var(--color-secondary)"
                        type="button"
                        icon={IconNames.close}
                      />
                    </div>
                  )}
                  {type && <div className={styles.icon}>{iconMap[type]}</div>}
                  {title && <div className={styles.title}>{title}</div>}
                  {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                  {body}
                  {(onDeny || onConfirm) && (
                    <div className={styles.action}>
                      {onDeny && (
                        <Button label="Deny" onClick={onDeny} variant={ButtonVariants.secondary} type="button" />
                      )}
                      {onConfirm && <Button label="Confirm" onClick={onConfirm} type="button" />}
                    </div>
                  )}
                </div>
              </div>
            </motion.section>
          </div>
          )
        </AnimatePresence>,
        document.body,
      )}
    </>
  )
}
