import { useInfiniteQuery, useQuery } from 'react-query'
import { pushNotification } from 'src/services/notifications/notificationService'
import { api } from './api'

export const useGetImages = () =>
  useQuery('images', async () => {
    try {
      const response = await api.getImages()
      const [error, data] = response as any[]
      console.log(error)

      return data || null
    } catch (e) {
      console.error(e)

      if (e?.toString().includes('e_account_blocked')) {
        // show notification
        pushNotification({
          type: 'error',
          title: 'Your account is blocked.',
        })
        // redirect back
        window.location.href = '/blocked'

        throw new Error('')
      }

      return null
    }
  })

export const useGetArticleById = (id) =>
  useQuery(
    ['article', id],
    async () => {
      try {
        const response = await api.getArticle({ id })
        const [error, data] = response as any[]
        console.log(error)

        return data || null
      } catch (e) {
        console.error(e)

        if (e?.toString().includes('e_account_blocked')) {
          // show notification
          pushNotification({
            type: 'error',
            title: 'Your account is blocked.',
          })
          // redirect back
          window.location.href = '/blocked'

          throw new Error('')
        }

        return null
      }
    },
    {
      enabled: !!id,
    },
  )

export const useGetBlog = () =>
  useInfiniteQuery(
    'blog',
    async ({ pageParam = 1 }) => {
      try {
        const response = await api.getArticles({ page: pageParam })
        const [error, data] = response as any[]

        if (error) {
          if (error.toString().includes('e_account_token_empty')) {
            return []
          }

          throw new Error(error)
        }

        return data
      } catch (error) {
        // eslint-disable-next-line
        // @s-ignore
        // throw new Error(error?.message)
        return error
      }
    },

    {
      getNextPageParam: (lastPage: any) =>
        // lastPage?.length >= 20 ? pages.length * 20 : false

        lastPage?.pagination?.next_page ?? undefined,
      onError: (error: any) => console.log('WTF', error),
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      keepPreviousData: false,
    },
  )
