/* eslint-disable react/no-unescaped-entities */
import styles from './index.module.scss'

export function TermsPage() {
  return (
    <div className={styles.dashboard}>
      <div className={styles.feed}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>Terms of use</h1>
          </div>
          <h2>1. ACCEPTANCE OF TERMS</h2>

          <p>
            Welcome to picogen.io, a service offered by Muvon Co. Ltd. ("Company", "we", "us", or "our"). Your
            utilization of our services signifies your agreement to these Terms of Use ("Terms"), which may be updated
            by us occasionally.
          </p>

          <h2>2. ELIGIBILITY</h2>

          <p>
            You must be at least 13 years old to use picogen.io. By agreeing to these Terms, you are confirming that you
            are at least 13 years old.
          </p>

          <h2>3. SERVICES</h2>

          <p>
            Picogen.io offers an API permitting developers to integrate various image models including but not limited
            to dall-e, stability, and midjourney ("Services"). These services operate on a credit system, where each
            instance of image generation consumes credits depending on the model used. In order to utilize the
            Midjourney API, it is essential to possess an untouchable deposit of 5000 credits or have a verified
            account.
          </p>

          <h2>4. SUBSCRIPTION AND CREDITS</h2>

          <p>
            Users may subscribe for additional features such as the capability to make more concurrent requests. Free
            plan users are limited to one generating job at a time. Users can also acquire credits to generate images.
            We determine the cost of credits, which is subject to change. Credits are non-refundable except as
            explicitly mentioned in these Terms.
          </p>

          <h2>5. OWNERSHIP AND LICENSE</h2>

          <p>
            All images generated through our Services are property of the Company. However, we provide Unpaid Members a
            license under the Creative Commons Noncommercial 4.0 Attribution International License (the "Asset License")
            for the Assets they create. The full text of this license can be accessed here:
            https://creativecommons.org/licenses/by-nc/4.0/legalcode. For Paid Customers, who have an active
            subscription or/and minimum balance of 1000 credits, the generated assets are granted with a general
            commercial license.
          </p>

          <h2>6. REFUND POLICY</h2>

          <p>
            New users may request a refund of 50% of their purchased credits within one week of account registration,
            given that the account is cancelled. No refunds will be provided for accounts registered for over one week.
            Subscriptions are non-refundable. Refunds only apply to deposits made for purchasing credits.
          </p>

          <h2>7. ERRORS AND REFUNDS</h2>

          <p>
            In the event of an error during image generation not caused by user's actions or inactions, we will refund
            the credits used for that task. The refund process is automated.
          </p>

          <h2>8. WARRANTY DISCLAIMER</h2>

          <p>
            Our Services, along with the generated assets, are provided "as is", without any warranties or conditions
            including but not limited to warranties or conditions of title, non-infringement, merchantability, or
            fitness for a specific purpose. You bear the sole responsibility for determining the suitability of using or
            redistributing the Assets and any risks associated with the use of the Services.
          </p>

          <h2>9. CHANGES TO THE TERMS</h2>

          <p>
            We reserve the right to modify these Terms at any time. Your continued use of our Services following any
            such changes indicates your acceptance of the revised Terms.
          </p>

          <h2>10. USER CONDUCT</h2>

          <p>
            Users of picogen.io are expected to use the Services responsibly and with respect for the rights and
            freedoms of others. Any actions taken by a user that could potentially lead to service unavailability, such
            as but not limited to, spamming, reporting, hacking, or other forms of misuse, will not be tolerated. Upon
            detection of such activities, we reserve the right to permanently ban the offending user's account.
          </p>

          <h2>11. TERMINATION AND REFUNDS</h2>

          <p>
            In cases where a user has been banned due to negative actions affecting our service availability, no refund,
            either for subscriptions or credits, will be provided. This stands irrespective of any previous terms
            mentioned in these Terms of Use. Such termination is at the sole discretion of Muvon Co. Ltd., and the
            decision is final and binding.
          </p>

          <p>
            By using the Services provided by picogen.io, you signify your understanding of, and agreement to these
            Terms of Use.
          </p>

          <p>Muvon Co. Ltd. Phuket, Thailand</p>
        </div>
      </div>
    </div>
  )
}
