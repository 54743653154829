import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { useLogoutUser } from 'src/modules/currentUser/query'
import styles from './index.module.scss'

export function BlockedPage() {
  const { mutate } = useLogoutUser()

  const onLogoutHandler = async () => {
    // eslint-disable-next-line
    await mutate()
    window.location.href = '/terms-of-use'
  }

  return (
    <Container>
      <div className={styles.wrapper}>
        <div>
          <h1>You are blocked.</h1>
          <img src="https://api.picogen.io/files/202309/06/9742f9309f695ba414938f37825827a6.png" alt="banana" />
          <Button onClick={onLogoutHandler} label="Read terms of use" type="button" />
        </div>
      </div>
    </Container>
  )
}
